<template>
  <el-cascader
    ref="cascader"
    v-model="addr"
    :props="options"
    :disabled="disabled"
    @change="change"
  />
</template>
<script>
import { queryByParentIdAndName } from '@/api';

const id = 0;
export default {
  props: {
    value: {
      type: String
    },
    area: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    refresh: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      addr: [],
      options: {
        lazy: true,
        lazyLoad: (node, resolve) => {
          this.queryByParentIdAndName(node, resolve);
        }
      }
    };
  },
  watch: {
    value: {
      handler(value) {
        if (value) {
          this.addr = (value.split('_') || []).map((item) => Number(item));
          if (this.refresh) {
            this.$refs.cascader.panel.lazyLoad();
            this.$emit('update:refresh', false);
          }
        } else {
          this.addr = [];
        }
      },
      immediate: true
    }
  },
  methods: {
    change(val) {
      const str = val.join('_');
      this.$emit('input', str);
    },
    queryByParentIdAndName(node, resolve) {
      const params = {
        parentId: node?.value
      };
      queryByParentIdAndName(params).then((res) => {
        const nodes = (res?.data || []).map((item) => ({
          value: item.id,
          label: item.name,
          leaf: node?.level >= 2
        }));
        resolve(nodes);
      });
    }
  },
};
</script>
